import React from "react";
import classNames from "classnames";

const HeaderCardFeatures = ({ features, dualCommodity, productid }) => {
  return (
    features?.length > 0 &&
    features.map((feature, key) => (
      <div
        key={key}
        className={classNames("card-offer__feature d-flex justify-content-between py-2", {
          "card-offer__feature--border-bottom pb-4 mb-4": feature.hr && dualCommodity,
        })}
      >
        <div
          className={classNames("card-offer__feature-text d-flex mr-4", {
            "card-offer__feature-text--bold": feature.isBold,
          })}
        >
          <span dangerouslySetInnerHTML={{ __html: feature.label }} />
          {feature.isTooltip && (
            <div className="card-offer__tooltip active card-offer__tooltip--moveup d-none d-md-block">
              <span className="card-offer__tooltip-label">i</span>
              <div className="card-offer__tooltip-text">{feature.tooltip}</div>
            </div>
          )}
        </div>
        <div
          className="card-offer__feature-price w-50"
          dangerouslySetInnerHTML={{ __html: feature.value }}
        />
      </div>
    ))
  );
};

export default HeaderCardFeatures;
